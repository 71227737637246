<template>
  <div class="login_container">
    <div class="main">
      <div class="Addcard_header">
        <span :class="{ addbac: index == 0 }" @click="onclickTab(0)"
          >已建档</span
        >
        <span :class="{ addbac: index == 1 }" @click="onclickTab(1)"
          >未建档</span
        >
      </div>
    </div>
    <div class="box_content" v-if="index == 0">
      <van-cell-group>
        <van-field
          v-model="Username"
          label="姓名"
          placeholder="请输入您的姓名"
          left-icon="manager-o"
        />
        <!-- <van-field v-model="Username" label="姓名" placeholder="请输入您的姓名" left-icon="manager-o"/> -->
        <van-field
          v-model="Managecard"
          label="证件号"
          placeholder="请输入您的身份证或护照号"
          maxlength="18"
          left-icon="peer-pay"
        />
        <van-field
          v-model="TelNumber"
          label="手机号"
          placeholder="请输入您的手机号"
          maxlength="11"
          type="number"
          left-icon="phone-o"
        />
        <van-field
          v-model="Address"
          label="家庭住址"
          placeholder="请输入您的家庭住址"
          left-icon="home-o"
        />
        <!-- <van-field v-model="Medicalcard" label="就诊卡号" placeholder="请输入您的就诊卡号" left-icon="credit-pay"/> -->
      </van-cell-group>
    </div>
    <div class="box_content" v-else>
      <van-cell-group>
        <van-field
          v-model="Username"
          label="姓名"
          placeholder="请输入您的姓名"
          left-icon="manager-o"
        />
        <van-field
          v-model="Managecard"
          label="证件号"
          maxlength="18"
          placeholder="请输入您的身份证或护照号"
          left-icon="peer-pay"
        />
        <van-field
          v-model="Sex"
          label="性别"
          placeholder="请输入您的性别"
          left-icon="user-circle-o"
        />
        <van-field
          v-model="Age"
          label="年龄"
          placeholder="请输入您的年龄"
          left-icon="contact"
        />
        <van-field
          v-model="TelNumber"
          label="手机号"
          placeholder="请输入您的手机号"
          maxlength="11"
          type="number"
          left-icon="phone-o"
        />
        <van-field
          v-model="Address"
          label="家庭住址"
          placeholder="请输入您的家庭住址"
          left-icon="home-o"
        />
        <van-field
          readonly
          v-model="Birthday"
          label="出生日期"
          placeholder="请输入您的出生日期"
          left-icon="calender-o"
          @click="show = true"
        />
        <van-popup v-model="show" position="bottom" :style="{ height: '50%' }">
          <van-datetime-picker
            type="date"
            v-model="currentDate"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="show = false"
            :formatter="formatter"
            @confirm="onConfirm"
          />
        </van-popup>
      </van-cell-group>
    </div>
    <div class="Addcard_hint">
      *温馨提示：请认真填写信息，绑定的信息将作为就诊凭证
    </div>
    <van-checkbox
      v-model="checked"
      checked-color="#57c4b7"
      shape="square"
      style="margin: 8% 17%"
    >
      <a @click="onuseragree" style="color: #ababab"
        >阅读并同意《用户协议》</a
      ></van-checkbox
    >
    <van-button
      round
      type="primary"
      size="large"
      style="width: 90%; background: #57c4b7; margin: 2% 5%"
      @click="onClickaddM()"
      ><span style="color: white">添加</span></van-button
    >
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import ajax from "../../lib/ajax";
import store from "../../store";
export default {
  data() {
    return {
      show: false,
      checked: true,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      index: 1,
      Username: "",
      Sex: "",
      Age: "",
      Birthday: "",
      Medicalcard: "",
      Managecard: "",
      TelNumber: "",
      Address: "",
      PassWord: "000000",
    };
  },
  methods: {
    formatDate(date) {
      let Month =date.getMonth() + 1;
      let Day=date.getDate();
      //console.log(`${date.getFullYear()}/${Month<=9?'0'+Month:Month}/${Day<=9?'0'+Day:Day}`)
       return `${date.getFullYear()}/${Month<=9?'0'+Month:Month}/${Day<=9?'0'+Day:Day}`;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    showPopup() {
      this.show = true;
    },
    onuseragree() {
      this.$router
        .replace({
          path: "UA",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onConfirm(date) {
      this.show = false;
      this.Birthday = `${this.formatDate(date)}`;
    },
    onclickTab(idx) {
      this.index = idx;
    },
    onEnter() {
      let me = this;
      let date = new Date();
      let sexs = me.Managecard.substring(16, 17);
      let ages = me.Managecard.substring(6, 10);
      let dates =
        ages +
        "/" +
        me.Managecard.substring(10, 12) +
        "/" +
        me.Managecard.substring(12, 14);
      me.Sex = sexs % 2 > 0 ? "男" : "女";
      me.Age = date.getFullYear() - ages;
      me.Birthday = dates;
    },
    async onClickaddM() {
      var wxopenid = store.getters["wechat/openid"];
      let ownerIDCard = store.getters["store/owneridcard"];
      // alert(wxopenid);
      var me = this;
      // if (me.index == 0) {
        if (me.Username == "" || me.Username.length == 0) {
          Toast("姓名不能为空！");
          return;
        } else if (me.Managecard == "" || me.Managecard.length == 0) {
          Toast("证件号不能为空！");
          return;
        } else if (me.Managecard.length != 9 && me.Managecard.length != 18) {
          Toast("请核对证件号后再进行操作！");
          return;
        } else if (me.TelNumber == "" || me.TelNumber.length == 0) {
          Toast("电话号码不能为空！");
          return;
        } else if (me.Address == "" || me.Address.length == 0) {
          Toast("家庭住址不能为空！");
          return;
        } else if (me.TelNumber.length != 11) {
          Toast("请核对电话号码后再进行操作！");
          return;
        }
        let sex = this.Sex;
        // let a={   openID: wxopenid,
        //     cardNo: me.Managecard,
        //     phone: me.TelNumber,
        //     orderId: 0,
        //     sex: sex,
        //     birthday: me.Birthday,
        //     userName: me.Username,
        //     idCard: me.Managecard,
        //     familyAddress: me.Address,
        //     ownerIDCard: ownerIDCard,
        //     machineName: "wechat",
        //     operatorId: "100002",}
        //     console.log(a);
        await ajax
          .post("/Api/CardService/WeChatRegistOther", {
            openID: wxopenid,
            phone: me.TelNumber,
            orderId: 0,
            sex: sex,
            birthday: me.Birthday,
            userName: me.Username,
            idCard: me.Managecard,
            familyAddress: me.Address,
            ownerIDCard: ownerIDCard,
            machineName: "wechat",
            operatorId: "100002",
          })
          .then((res) => {
            console.log(res);
            if(res.data.result>0){
                  Toast("添加成功");
                this.$router
                  .replace({
                    path: "/ulist",
                  })
                  .catch((err) => {
                    console.log(err);
                  });
            }else if(res.data.result==-1){
                  Toast("添加失败 ,找不到OwnerIDCard");
            }else if(res.data.result==-2){
                  Toast("添加失败，无法建立自我从属关系");
            }else if(res.data.result==-3){
                  Toast("添加失败,从属his注册失败");
            }
            else if(res.data.result==-4){
                  Toast("添加失败,查询患者his失败");
            }
            
          })
          .catch((err) => {
            console.log(err);
            Toast("就诊卡添加错误，请核对后再进行添加");
          });
      // } else if (this.index == 1) {
      //   if (me.Username == "" || me.Username.length == 0) {
      //     Toast("姓名不能为空！");
      //     return;
      //   } else if (me.Managecard == "" || me.Managecard.length == 0) {
      //     Toast("证件号不能为空！");
      //     return;
      //   } else if (me.Managecard.length != 9 && me.Managecard.length != 18) {
      //     Toast("请核对证件号后再进行操作！");
      //     return;
      //   } else if (me.Sex == "" || me.Sex.length == 0) {
      //     Toast("性别不能为空！");
      //     return;
      //   } else if (me.Age == "" || me.Age.length == 0) {
      //     Toast("年龄不能为空！");
      //     return;
      //   } else if (me.TelNumber == "" || me.TelNumber.length == 0) {
      //     Toast("电话号码不能为空！");
      //     return;
      //   } else if (me.Address == "" || me.Address.length == 0) {
      //     Toast("家庭住址不能为空！");
      //     return;
      //   } else if (me.Birthday == "" || me.Birthday.length == 0) {
      //     Toast("出生日期不能为空！");
      //     return;
      //   } else if (me.TelNumber.length != 11) {
      //     Toast("请核对电话号码后再进行操作！");
      //     return;
      //   }
      //   let sex = false;
      //   if (me.Sex == "男") {
      //     sex = true;
      //   }
      //   await ajax
      //     .post("/Api/CardService/WeChatRegistOther", {
      //       openID: wxopenid,
      //       cardNo: me.Managecard,
      //       phone: me.TelNumber,
      //       orderId: 0,
      //       sex: sex,
      //       birthday: me.Birthday,
      //       userName: me.Username,
      //       idCard: me.Managecard,
      //       familyAddress: me.Address,
      //       ownerIDCard: ownerIDCard,
      //       machineName: "wechat",
      //       operatorId: "0733",
      //     })
      //     .then((res) => {
      //       console.log(res);
      //       Toast("添加成功");
      //       this.$router
      //         .replace({
      //           path: "/ulist",
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       Toast("添加失败，请稍后再试！");
      //     });
      // }
    },
  },
  watch:{
     Managecard(old){
       if(old.length==18){
          this.onEnter()
       }
     }
  }
};
</script>
<style lang="less" scoped>
.login_container {
  height: 100%;
}
.addbac {
  background-color: #30b9a9;
  color: #fff;
}
.Addcard_header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border: 1px solid #f1f1f1;
  span {
    text-align: center;
    width: 50%;
    float: left;
  }
  .box_content {
    width: 100%;
  }
}
.list {
  height: 40px;
  width: 100%;
  line-height: 50px;
  border: 1px solid #f1f1f1;
  color: red;
  input {
    height: 40px;
    line-height: 50px;
    padding-left: 20px;
  }
}
.Addcard_hint {
  margin: 20px 10px 0px 5px;
  color: #57c4b7;
  font-size: 75%;
}
.Addcard_checkbox {
  margin: 50px;
}
</style>
